import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import "../css/style.css";
import * as ROUTES from "../consts/routes";
import { auth, db } from "../services/firebase";
import logoImg from "../photos/logo-large.png";
//@ts-ignore
import Loader from "react-loader-spinner";
import { ChildrenCard } from "../components/ChildrenCard";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
//@ts-ignore
import Swal from "@sweetalert/with-react";
import swal from "sweetalert";
import Recaptcha from "react-recaptcha";
import { childrenObj } from "./screens.modal";
import { FirebaseError } from "firebase";

const SignUp = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [children, setChildren] = useState<childrenObj[]>([]);
  const [addChild, setAddChild] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaRes, setCaptchares] = useState<string>("");
  const [readyCaptcha, setReadyCaptcha] = useState(false);
  const [addChildId, setAddChildId] = useState("");

  const verifyCallback = (response: string) => setCaptchares(response);
  const callback = () => setReadyCaptcha(true);

  const spinner = useCallback(() => {
    if (loading) {
      return (
        <Loader
          style={{ marginTop: "20px" }}
          type="Circles"
          color="#a64bf4"
          height={50}
          width={50}
        />
      );
    } else {
      return <div></div>;
    }
  }, [loading]);

  const handleSend = async () => {
    setLoading(true);

    if (!readyCaptcha || captchaRes === null) {
      setLoading(false);
      Swal(
        <div>
          <h1 style={{ color: "red" }}>אנא מלאו את ה-Captcha</h1>
        </div>
      );
      return;
    }
    if (surname === "") {
      setLoading(false);
      Swal(
        <div>
          <h1 style={{ color: "red" }}>לא הוכנס שם משפחה</h1>
          <p>רישמו את שם המשפחה</p>
        </div>
      );
      return;
    }
    if (phone === "") {
      setLoading(false);
      Swal(
        <div>
          <h1 style={{ color: "red" }}>לא הוכנס טלפון</h1>
          <p>רישמו את הטלפון שלכם</p>
        </div>
      );
      return;
    }
    if (name === "") {
      setLoading(false);
      Swal(
        <div>
          <h1 style={{ color: "red" }}>לא הוכנס שם</h1>
          <p>רישמו את שימכם</p>
        </div>
      );
      return;
    }
    if (children.length === 0) {
      await setLoading(false);
      Swal(
        <div>
          <h1 style={{ color: "red" }}>לא הוכנס שמות ילדיכם</h1>
          <p>רישמו את שמם ולחצו הוסף</p>
        </div>
      );
      return;
    }
    try {
      const res = await auth().createUserWithEmailAndPassword(email, password);
      const user = auth().currentUser;
      await user?.updateProfile({
        displayName: `${surname}`,
      });

      await res?.user?.sendEmailVerification();
      await db.ref("users/" + res?.user?.uid).set({
        username: name,
        phone,
        userSurename: surname,
        email,
      });
      const ref = db.ref(`/users/${res?.user?.uid}/childrens`);
      for (let i = 0; i < children.length; i++) {
        await ref.push().set(children[i], (error) => {
          if (error) {
            swal({
              title: "תקלה בהוספת ילדים",
              text: "אנא פנו אל העמותה להוספה ידנית, עמכם הסליחה",
              icon: "error",
            });
          }
        });
      }
      setPhone("");
      setName("");
      setSurname("");
      setEmail("");
      setPassword("");
      setAddChild("");
      setChildren([]);
      Swal(
        <div>
          <h1 style={{ color: "#a64bf4" }}>אישור אימייל</h1>
          <p>ברגע זה נשלח אליכם אימייל שעליכם לאשר אותו בכדי להיכנס לאתר</p>
        </div>
      );
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
      let message;
      const errorCode = error.code;
      switch (errorCode) {
        case "auth/weak-password":
          message = "סיסמא לא תקינה, נדרש לפחות 6 תווים";
          break;
        case "auth/invalid-email":
          message = "האימייל לא נכתב כראוי";
          break;
        default:
          message =
            "אירעה שגיאה, כנראה וכבר נרשמתם למערכת, אם לא אנא פנו אל מזכירות בית הספר";
      }
      Swal(
        <div>
          <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
          <p>
            {message}
            <br />
            <a href={`${process.env.PUBLIC_URL}${ROUTES.SIGN_IN}`}>
              עבור למסך כניסת משתמשים
            </a>
          </p>
        </div>
      );
    }
  };
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const changePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const letter = e.target.value;
    const regex = letter
      .split("")
      .every((letter: string) => letter.match(/^[\w.!@#$%^&*()-+]+$/i));
    if (!regex) {
      return Swal(
        <div>
          <h1 style={{ color: "red" }}>סיסמא לא חוקית</h1>
          <p>הסיסמה חייבת להכיל אותיות באנגלית או ספרות או סימנים</p>
        </div>
      );
    }
    setPassword(e.target.value);
  };

  const handleKeyDownChildren = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 32) {
      return Swal(
        <div>
          <h1 style={{ color: "red" }}>נא להכניס שמות פרטיים בלבד</h1>
        </div>
      );
    }
  };

  const setPhoneText = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 10) {
      return Swal(
        <div>
          <h1 style={{ color: "red" }}>מספר טלפון לא חוקי</h1>
        </div>
      );
    }
    setPhone(e.target.value);
  };

  return (
    <div className="container heightFix">
      <div className="row" dir="rtl">
        <div className="loginFrame">
          <h1 className="center">עמותת בית ספר אלחריזי</h1>
          <img
            src={logoImg}
            className="imgCenter"
            width={"30%"}
            alt="עמותת הורים אלחריזי"
          />
          <h1 className="center">שלום אורח</h1>
          <h2 className="center">אנא הירשם לאתר</h2>
          <div className="center column">
            <div className="row spaceSide">
              <span className="inputTitle">שם פרטי:</span>
              <input
                className="input"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="row spaceSide">
              <span className="inputTitle">שם משפחה:</span>
              <input
                className="input"
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </div>
            <div className="row spaceSide">
              <span className="inputTitle">מספר טלפון:</span>
              <input
                className="input"
                onSubmit={handleSend}
                type="text"
                value={phone}
                onChange={setPhoneText}
              />
            </div>
            <div
              className="row spaceSide"
              style={{ marginTop: 15, marginBottom: -10 }}
            >
              <span className="inputTitle childrenTitle">ילדים</span>
            </div>

            <div
              className="row spaceSide"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <span className="inputTitle childrenTitle">שם:</span>
              <input
                className="input heb modalInput"
                type="text"
                onKeyDown={handleKeyDownChildren}
                value={addChild}
                onChange={(e) => setAddChild(e.target.value)}
              />
              <span className="inputTitle childrenTitle">:ת.ז</span>

              <input
                className="input heb modalInput"
                type="text"
                value={addChildId}
                onChange={(e) => setAddChildId(e.target.value)}
              />
              <button
                className="addButton"
                onClick={() => {
                  if (!addChild.length) {
                    return swal({
                      title: "חסר שם",
                      text: "מלאו את שם הילד",
                      icon: "error",
                    });
                  }
                  const childrenWithId = {
                    name: addChild,
                    id: addChildId,
                  };
                  setChildren([...children, childrenWithId]);
                  setAddChild("");
                  setAddChildId("");
                }}
              >
                הוסף
              </button>
            </div>
            <div className="row spaceSide childrenWrap">
              {children?.map((child, index) => {
                return (
                  <ChildrenCard
                    key={`${child}${index}`}
                    child={child.name}
                    removeChild={() => {
                      const arr = [...children];
                      const index = arr.indexOf(child);
                      if (index !== -1) arr.splice(index, 1);
                      setAddChild("");
                      setAddChildId("");
                      setChildren(arr);
                    }}
                  />
                );
              })}
            </div>
            <div className="row spaceSide">
              <span className="inputTitle">אימייל:</span>
              <input
                className="input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="row spaceSide">
              <span className="inputTitle">סיסמא:</span>
              <input
                className="input"
                onKeyDown={handleKeyDown}
                onSubmit={handleSend}
                type="password"
                value={password}
                onChange={changePassword}
              />
            </div>
          </div>
          <div className="center column">
            <div className="row spaceSide">
              <span className="inputTitle question">כבר נרשמתם?</span>
              <span
                className="toLoginBtn"
                onClick={async () => {
                  if (name.length > 0) {
                    await handleSend();
                  }
                }}
              >
                {!name.length ? (
                  <Link to={ROUTES.SIGN_IN}>כניסה</Link>
                ) : (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a>כניסה</a>
                )}
              </span>
            </div>
            <div className="row captcha">
              <Recaptcha
                sitekey="6Lc09MkZAAAAAL9OT_uuzJMG2VVipwDkC_qjy4zF"
                render="explicit"
                verifyCallback={verifyCallback}
                onloadCallback={callback}
              />
            </div>
            {spinner()}
            <div className="centerBtn">
              <button className="loginBtn" onClick={handleSend}>
                שלח
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
