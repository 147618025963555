export const classProgramFunc = (program) => {
  if (program) {
    switch (true) {
      case program.value.includes("מצוינות בספורט עולים"):
        return [{ value: 0, label: "דמי רישום" }];
      case program.value === "ג׳ודו":
        return [
          { value: 0, label: "גן חובה וטרום חובה" },
          { value: 1, label: "גן חובה וכיתה א׳" },
          { value: 2, label: "ב׳" },
          { value: 3, label: "א׳-ג׳ יום שישי" },
          { value: 4, label: "ג׳-ד׳ יום שלישי" },
          { value: 5, label: "אגודה" },
        ];
      case program.value === "התעמלות קרקע ואקרובטיקה": {
        return [
          { value: 0, label: "קבוצה ראשונה" },
          { value: 1, label: "קבוצה שנייה" },
        ];
      }
      case program.value === "ריקודי עם": {
        return [
          { value: 0, label: "מתחילים" },
          { value: 1, label: "ממשיכים/מתקדמים" },
        ];
      }
      case program.value === "הייטק וגימינג": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
          { value: 2, label: "ה׳-ו׳" },
        ];
      }
      case program.value === "לגו מכני": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
        ];
      }
      case program.value === "משקיעי העתיד": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
          { value: 2, label: "ה׳-ו׳" },
        ];
      }
      case program.value === "דמי רישום מצויינות בספורט": {
        return [
          { value: 0, label: "עולים לד׳" },
          { value: 1, label: "עולים לג׳" },
        ];
      }
      case program.value === "אומנות ויצירה": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
          { value: 2, label: "טרום חובה וחובה" },
        ];
      }
      case program.value === "מיניאטורות ציור ושילוב אומנויות": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
        ];
      }
      case program.value === "חינוך פיננסי": {
        return [{ value: 0, label: "שכבות ד-ו יום שני 13:30" }];
      }
      case program.value === "שחמט": {
        return [
          { value: 0, label: "מתחילים" },
          { value: 1, label: "מתקדמים" },
        ];
      }
      case program.value === "כדורגל":
        return [
          { value: 1, label: "מתחילים" },
          { value: 2, label: "א׳-ב׳" },
          { value: 3, label: "ג׳-ד׳" },
        ];
      case program.value === "ברייקדאנס":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
          { value: 3, label: "ה׳-ו׳" },
        ];
      case program.value === "נגרות":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
          { value: 3, label: "ה׳-ו׳" },
        ];
      case program.value === "משחק המלכים":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
          { value: 3, label: "ה׳-ו׳" },
        ];
      case program.value === "תשלומי עמותה": {
        return [
          { value: 1, label: "יש לי ילד אחד בבית הספר" },
          { value: 2, label: "יש לי שני ילדים בבית הספר" },
          { value: 3, label: "יש לי שלושה ילדים ומעלה בבית הספר" },
        ];
      }

      case program.value === "חוג ציור בטכניקת פורינג": {
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
          { value: 3, label: "ה׳-ו׳" },
        ];
      }
      case program.value === "מבוכים ודרקונים":
        return [
          { value: 1, label: "ב׳-ג׳" },
          { value: 2, label: "ד-ו׳" },
        ];
      case program.value === "עיצוב אופנה ותפירה":
        return [
          { value: 1, label: "א׳-ג׳" },
          { value: 2, label: "ד׳-ו׳" },
        ];
      case program.value === "כדור יד":
        return [{ value: 1, label: "ד׳-ה׳" }];
      case program.value === "בית הספר לקסמים":
        return [{ value: 1, label: "א׳-ד׳" }];
      case program.value === "צהרון קיץ":
        return [{ value: 1, label: "א׳-ג׳" }];
      case program.value === "היפ-הופ":
        return [{ value: 1, label: "יום ד׳" }];
      case program.value === "התעמלות אומנותית":
        return [
          { value: 1, label: "גילאי גן 45 דק׳" },
          { value: 2, label: "א׳-ג׳ 45 דק׳" },
        ];
      case program.value === "טאקוונדו":
        return [
          { value: 1, label: "א׳-ג׳" },
          { value: 2, label: "ד׳-ו׳" },
          { value: 3, label: "נוער ובוגרים" },
        ];
      case program.value === "קרב מגע לילדים":
        return [{ value: 1, label: "א-ו יום שני" }];
      case program.value === "האלקטרונאי הצעיר":
        return [{ value: 1, label: "א׳-ד׳" }];

      case program.value === "טריקינג":
        return [
          { value: 1, label: "ד׳-ו׳" },
          { value: 2, label: "מבוגרים" },
        ];
      case program.value === "יסמין בהרפתקאה במוזיאון":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
        ];
      case program.value === "אנגלית":
        return [
          { value: 1, label: "כיתה ג׳" },
          { value: 2, label: "כיתה ד" },
          { value: 3, label: "כיתה ה-ו" },
        ];
      case program.value === "כדורעף":
        return [
          { value: 1, label: "מתקדמות ה׳-ו׳ בנות 14:30" },
          { value: 2, label: "15:15 בנים ה׳-ו׳" },
          { value: 3, label: "16:00 בנות ג׳-ד׳" },
          { value: 4, label: "מתחילות ה׳-ו׳ בנות 16:45" },
        ];
      case program.value === "כלבנות ואילוף כלבים":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ו׳" },
        ];
      case program.value === "סדנא להגנה עצמית":
        return [{ value: 0, label: "4 מפגשים" }];
      case program.value === "רולר דאנס":
        return [
          { value: 0, label: "גן טרום חובה וחובה" },
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ו׳" },
        ];
      case program.value === "קרב מגע": {
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ו׳" },
          { value: 2, label: "נשים" },
        ];
      }
      case program.value === "לינור ציור פורינג ומיקס מדיה באומנות": {
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ד׳" },
          { value: 3, label: "ה׳-ו׳" },
        ];
      }
      case program.value === "לינור תכשיטנות במקרמה וחרוזים": {
        return [
          { value: 0, label: "מתחילים" },
          { value: 1, label: "מתקדמים" },
        ];
      }
      case program.value === "פינת החי-חיות טבע": {
        return [
          { value: 1, label: "שני גן חובה וא׳-ב׳" },
          { value: 2, label: "ראשון גן חובה וא׳-ב׳" },
          { value: 3, label: "שישי ג׳-ו׳" },
        ];
      }
      case program.value === "גומיקליי": {
        return [{ value: 1, label: "א׳-ג׳" }];
      }
      case program.value === "כדוריד": {
        return [
          { value: 1, label: "ראשון שכבות ה-ו" },
          { value: 2, label: "רביעי שכבות ה-ו" },
          { value: 3, label: "רביעי שכבת ד" },
          { value: 4, label: "ראשון ורביעי שכבות ה-ו" },
        ];
      }
      case program.value === "חוג מתמטיקה":
        return [{ value: 0, label: "שכבת ו יום שני 13:30-14:15" }];
      case program.value === "פעילות סוכות":
        return [{ value: 0, label: "כללי" }];
      case program.value === "סייבר סקול":
        return [
          { value: 0, label: "א׳-ב׳" },
          { value: 1, label: "ג׳-ד׳" },
          { value: 2, label: "ה׳-ו׳" },
        ];
      case program.value === "בית הספר לקוסמים":
        return [{ value: 1, label: "א׳-ד׳" }];
      case program.value === "מצויינות בספורט":
        return [
          // { value: 1, label: "א׳-ב׳" },
          // { value: 2, label: "ג׳-ה׳" },
          { value: 3, label: "ג׳-ד׳" },
        ];
      case program.value === "צהרון 2023-2024":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳" },
        ];
      case program.value === "מצויינות קרקע ומכשירים":
        return [{ value: 1, label: "שכבות א-ב" }];
      case program.value === "טיסנאות":
        return [
          { value: 1, label: "א׳-ב׳" },
          { value: 2, label: "ג׳-ו׳" },
        ];
      case program.value === "מהנדסים צעירים":
        return [{ value: 1, label: "א׳-ג׳" }];
      case program.value === "סטודיו מיתרים":
        return [
          { value: 1, label: "שיעור פרטי" },
          // { value: 2, label: "גיטרה פרטני" },
          // { value: 3, label: "אורגנית פרטני" },
        ];
      default:
    }
  } else {
    return "";
  }
};
