import React, { useState } from "react";
import logoImg from "../photos/logo-large.png";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";

import Swal from "@sweetalert/with-react";
import "../css/style.css";

const Taekwondo = () => {
  const { id, email } = useParams();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState([]);
  const [phone, setPhone] = useState("");
  const subProgram =
    id === "2"
      ? { label: "פעמיים בשבוע", value: 2 }
      : { label: "פעם בשבוע", value: 1 };

  const sendData = () => {
    if (!name || !phone) {
      Swal(
        <div>
          <h1 style={{ color: "red" }}>חסרים פרטים</h1>
          <p dir="rtl">מלאו את הפרטים הנדרשים</p>
        </div>
      );
    } else {
      setLoading(true);
      const sendData = {
        program: { label: "טאקוונדו", value: "טאקוונדו" },
        arrStudent: name,
        subProgram,
        taxId: id,
        classProgram: { label: "חוץ" },
        userEmail: email ? email : "amuta.site@gmail.com",
        numberStudents: 1,
        linkWithoutUser: true,
        phoneNumber: phone,
        ap: null,
      };

      fetch(`${process.env.PUBLIC_URL}/ccinfo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendData),
      })
        .then((res) => res.json())
        .then((data) => {
          const urlRedirect = data.redirect;
          window.location = urlRedirect;
        })
        .catch((err) => {
          console.log(err);
          Swal(
            <div>
              <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
              <p dir="rtl">נא לפנות למזכירות להסדרת התשלום, עמכם הסליחה</p>
            </div>
          );
        });
    }
  };
  return (
    <div className="container">
      <div className="row">
        <div className="loginFrame">
          <img
            src={logoImg}
            style={{ paddingTop: 10 }}
            className="imgCenter"
            width={"20%"}
            alt="עמותת הורים אלחריזי"
          />
          <div className="modalHeader">
            <h1 style={{ textAlign: "center", color: "#4BB543", padding: 10 }}>
              מלאו את הפרטים הנדרשים לצורך העברת תשלום לחוג הטאקוונדו
            </h1>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={name}
              onChange={(e) => setName([e.target.value])}
            />
            <span className="inputTitle">:שם מלא (הורה)</span>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span className="inputTitle">:מספר טלפון</span>
          </div>
          <div className="row modal-space">
            <button className="sendBtn" onClick={sendData}>
              עבור לדף תשלום מאובטח
            </button>
          </div>
        </div>
      </div>
      {loading && (
        <Loader
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            textAlign: "center",
          }}
          type="Circles"
          color="#fb00ff"
          height={100}
          width={100}
        />
      )}
    </div>
  );
};

export default Taekwondo;
