export const activities = [
  // { value: "יסמין בהרפתקאה במוזיאון", label: "יסמין בהרפתקאה במוזיאון" },
  // { value: "צהרון קיץ", label: "צהרון קיץ" },
  { value: "ג׳ודו", label: "ג׳ודו" },
  // { value: "משחק המלכים", label: "משחק המלכים" },
  // { value: "היפ-הופ", label: "היפ-הופ" },
  { value: "התעמלות אומנותית", label: "התעמלות אומנותית" },
  { value: "טאקוונדו", label: "טאקוונדו" },
  { value: "לגו מכני", label: "לגו מכני" },
  { value: "הייטק וגימינג", label: "הייטק וגיימינג" },
  { value: "התעמלות קרקע ואקרובטיקה", label: "התעמלות קרקע ואקרובטיקה" },
  { value: "משקיעי העתיד", label: "משקיעי העתיד" },
  { value: "פינת החי-חיות טבע", label: "פינת החי-חיות טבע" },
  // { value: "דמי רישום מצויינות בספורט", label: "דמי רישום מצויינות בספורט" },
  // { value: "Dance it", label: "Dance it" },
  { value: "שחמט", label: "שחמט" },
  // { value: "פעילות סוכות", label: "פעילות סוכות" },
  { value: "חוג מתמטיקה", label: "חוג מתמטיקה" },
  { value: "ריקודי עם", label: "ריקודי עם" },
  { value: "טיסנאות", label: "טיסנאות" },
  // { value: "טריקינג", label: "טריקינג" },
  { value: "כדורעף", label: "כדורעף" },
  { value: "כדורגל", label: "כדורגל" },
  { value: "כדוריד", label: "כדוריד" },
  { value: "נגרות", label: "נגרות" },
  { value: "גומיקליי", label: "גומיקליי" },

  // { value: "ברייקדאנס", label: "ברייקדאנס" },
  // { value: "עיצוב אופנה ותפירה", label: "עיצוב אופנה ותפירה" },
  // { value: "בית הספר לקסמים", label: "בית הספר לקסמים" },
  { value: "כלבנות ואילוף כלבים", label: "כלבנות ואילוף כלבים" },
  { value: "מבוכים ודרקונים", label: "מבוכים ודרקונים" },
  // { value: "חוג ציור בטכניקת פורינג", label: "חוג ציור בטכניקת פורינג" },
  // {
  // { value: "אומנות ויצירה", label: "אומנות ויצירה" },
  //   value: "מיניאטורות ציור ושילוב אומנויות",
  //   label: "מיניאטורות ציור ושילוב אומנויות",
  // },
  // { value: "קרב מגע", label: "קרב מגע" },

  // { value: "חינוך פיננסי", label: "חינוך פיננסי" },
  // { value: "מחשבים", label: "מחשבים" },
  { value: "סטודיו מיתרים", label: "סטודיו מיתרים" },
  // {
  //   value: "לינור ציור פורינג ומיקס מדיה באומנות",
  //   label: "לינור ציור פורינג ומיקס מדיה באומנות",
  // },
  {
    value: "לינור תכשיטנות במקרמה וחרוזים",
    label: "לינור תכשיטנות במקרמה וחרוזים",
  },

  // { value: "סייבר סקול", label: "סייבר סקול" },
  // { value: "בית הספר לקוסמים", label: "בית הספר לקוסמים" },
  // { value: "מצויינות בספורט", label: "מצויינות בספורט" },
  { value: "קרב מגע לילדים", label: "קרב מגע לילדים" },
  { value: "רולר דאנס", label: "רולר דאנס" },
  // {value: "חקר המדע והרפואה", label: "חקר המדע והרפואה"},
  // {
  //   value: "מצוינות בספורט עולים לב- דמי רישום",
  //   label: "מצוינות בספורט עולים לב- דמי רישום",
  // },
  // {
  //   value: "מצוינות בספורט עולים לג- דמי רישום",
  //   label: "מצוינות בספורט עולים לג- דמי רישום",
  // },
  // {
  //   value: "מצוינות בספורט עולים לד- דמי רישום",
  //   label: "מצוינות בספורט עולים לד- דמי רישום",
  // },
  // {
  //   value: "מצוינות בספורט עולים לה- דמי רישום",
  //   label: "מצוינות בספורט עולים לה- דמי רישום",
  // },
  // {
  //   value: "מצוינות בספורט עולים לו- דמי רישום",
  //   label: "מצוינות בספורט עולים לו- דמי רישום",
  // },

  // { value: "אנגלית", label: "אנגלית" },
  { value: "האלקטרונאי הצעיר", label: "האלקטרונאי הצעיר" },
  // { value: "סדנא להגנה עצמית", label: "סדנא להגנה עצמית" },
  // { value: "צהרון 2023-2024", label: "צהרון 2023-2024" },
  // { value: "מצויינות קרקע ומכשירים", label: "מצויינות קרקע ומכשירים" },
  // { value: "תשלומי עמותה", label: "תשלומי עמותה" },
];
