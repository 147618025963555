import React from "react";
import { ChildrenCard } from "./ChildrenCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ModalUpdateProps } from "../screens/screens.modal";

export const ModalUpdate = ({
  setModal,
  changeName,
  setChangeName,
  changeLastName,
  setChangeLastName,
  changePhone,
  setChangePhone,
  schoolName,
  setSchoolName,
  addChildren,
  addChild,
  setAddChild,
  setChildren,
  children,
  removeChild,
  updateDetials,
  addChildId,
  setAddChildId,
}: ModalUpdateProps) => {
  return (
    <div className="modal">
      <div className="modal-container">
        <div className="modalHeader">
          <h1 className="center big modalHeaderOne">עדכון פרטים אישיים</h1>
          <FontAwesomeIcon
            size="2x"
            className="closeModal"
            icon={faTimesCircle}
            onClick={() => setModal(false)}
          />
        </div>
        <div className="row modal-space">
          <input
            className="input heb modalInput"
            type="text"
            value={changeName}
            onChange={(e) => setChangeName(e.target.value)}
          />
          <span className="inputTitle">:שם פרטי (הורה)</span>
        </div>
        <div className="row modal-space">
          <input
            className="input heb modalInput"
            type="text"
            value={changeLastName}
            onChange={(e) => setChangeLastName(e.target.value)}
          />
          <span className="inputTitle">:שם משפחה</span>
        </div>
        <div className="row modal-space">
          <input
            className="input heb modalInput"
            type="text"
            value={changePhone}
            onChange={(e) => setChangePhone(e.target.value)}
          />
          <span className="inputTitle">:מספר טלפון</span>
        </div>
        <div className="row modal-space">
          <input
            className="input heb modalInput"
            type="text"
            value={schoolName}
            onChange={(e) => setSchoolName(e.target.value)}
          />
          <span className="inputTitle">:שם בית הספר</span>
        </div>
        <div className="childrenSection">
          <h2 className="childrenHeader">ילדים</h2>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={addChildId}
              onChange={(e) => setAddChildId(e.target.value)}
            />
            <span className="inputTitle childrenTitle">:ת.ז</span>
            <input
              className="input heb modalInput"
              type="text"
              value={addChild}
              onChange={(e) => setAddChild(e.target.value)}
            />
            <span className="inputTitle childrenTitle">:שם</span>
          </div>
          <button
            className="addButton"
            onClick={() =>
              addChildren(
                addChild,
                addChildId,
                setAddChildId,
                setAddChild,
                children,
                setChildren
              )
            }
          >
            הוסף
          </button>
          <div className="childrenCard">
            {children?.map((child, index) => {
              return (
                <ChildrenCard
                  key={`${child}${index}`}
                  child={child.label}
                  removeChild={removeChild(children, child.label, setChildren)}
                />
              );
            })}
          </div>
        </div>
      </div>
      <button
        className="updateDetailsButton"
        onClick={() => {
          setModal(false);
          updateDetials(changePhone, changeLastName, changeName, setModal);
        }}
      >
        עדכן פרטים
      </button>
    </div>
  );
};
