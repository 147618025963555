import React, { useEffect } from "react";
import logoImg from "../photos/logo-large.png";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import { auth, db } from "../services/firebase";

import Swal from "@sweetalert/with-react";
import "../css/style.css";

const Ap = () => {
  const { id } = useParams();
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && id !== null) {
        const ref = db.ref(`/users/${user.uid}`);
        ref.once("value").then((snap) => {
          const snapshot = snap.val();
          const sendData = {
            program: { label: "תשלומי עמותה", value: "תשלומי עמותה" },
            arrStudent: ["תשלום"],
            subProgram: { label: 1, value: 1 },
            classProgram: { label: id },
            userEmail: snapshot.email,
            numberStudents: 1,
            phoneNumber: snapshot.phone,
            ap: null,
          };
          fetch(`${process.env.PUBLIC_URL}/ccinfo`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(sendData),
          })
            .then((res) => res.json())
            .then((data) => {
              const urlRedirect = data.redirect;
              window.location = urlRedirect;
            })
            .catch((err) => {
              console.log(err);
              Swal(
                <div>
                  <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
                  <p dir="rtl">נא לפנות למזכירות להסדרת התשלום, עמכם הסליחה</p>
                </div>
              );
            });
        });
      } else {
        Swal(
          <div>
            <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
            <p dir="rtl">אין לכם הרשאות לדף זה</p>
          </div>
        );
      }
    });
  }, [id]);
  return (
    <div className="container loginFix">
      <div className="row" dir="rtl">
        <div className="loginFrame">
          <img
            src={logoImg}
            style={{ paddingTop: 10 }}
            className="imgCenter"
            width={"20%"}
            alt="עמותת הורים אלחריזי"
          />
          <h1 style={{ textAlign: "center", color: "#4BB543", padding: 10 }}>
            נרשמתם בהצלחה! כעת אנחנו מעבירים אתכם לדף תשלום דמי עמותה!
          </h1>
          <Loader
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
            type="Circles"
            color="#fb00ff"
            height={100}
            width={100}
          />
        </div>
      </div>
    </div>
  );
};

export default Ap;
