import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../consts/routes";
import Swal from "@sweetalert/with-react";
import { auth } from "../services/firebase";
import logoImg from "../photos/logo-large.png";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "../css/style.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const get = localStorage.getItem("message");
    if (!get) {
      Swal({
        title: "ברוכים הבאים...",
        text: "באתר תוכלו לשלם לצהרון...",
        imageUrl: logoImg,
        imageWidth: 100,
        imageHeight: 100,
      });
      localStorage.setItem("message", "welcome");
    }
  }, []);

  const spinner = () => {
    if (loading) {
      return (
        <Loader
          style={{ marginTop: "20px", textAlign: "center" }}
          type="Circles"
          color="#a64bf4"
          height={50}
          width={50}
        />
      );
    }
    return null;
  };

  const handleSend = async () => {
    setLoading(true);
    auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        auth().onAuthStateChanged((user) => {
          if (!user.emailVerified) {
            Swal({
              title: "לא אישרתם את האימייל שלכם",
              text: "בעת הרישום...",
            });
            setLoading(false);
          }
        });
      })
      .catch((error) => {
        setLoading(false);
        let message;
        const errorCode = error.code;
        switch (errorCode) {
          case "auth/user-not-found":
            message = "אינכם רשומים לאתר, אנא הירשמו";
            break;
          case "auth/wrong-password":
            message = "סיסמא לא נכונה, נסו שנית או לחצו שכחתי סיסמא לאיפוס";
            break;
          case "auth/invalid-email":
            message = "האימייל לא נכתב כראוי";
            break;
          default:
            message = "אירעה שגיאה פנימית";
        }
        Swal({
          title: "אירעה שגיאה",
          text: message,
        });
      });
  };

  const forgot = () => {
    if (email === "") {
      Swal({
        title: "אימייל לא נרשם",
        text: "אנא רשמו את האימייל שלכם...",
      });
    } else {
      auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          Swal({
            title: "נשלחה אימייל לאיפוס סיסמא",
            text: "כנסו אל תיבת המייל שלכם...",
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changePassword = (e) => {
    const letter = e.target.value;
    const regex = letter
      .split("")
      .every((letter) => letter.match(/^[\w.!@#$%^&*()-+]+$/i));
    if (!regex) {
      return Swal({
        title: "סיסמא לא חוקית",
        text: "הסיסמה חייבת להכיל אותיות באנגלית...",
      });
    }
    setPassword(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  return (
    <div className="container loginFix">
      <div className="row" dir="rtl">
        <div className="loginFrame">
          <img
            src={logoImg}
            style={{ paddingTop: 10 }}
            className="imgCenter"
            width={"20%"}
            alt="עמותת הורים אלחריזי"
          />
          <h1 className="center">שלום</h1>
          <h2 className="center">אנא היכנס</h2>
          <div className="center column">
            <div className="row spaceSide">
              <span className="inputTitle">אימייל:</span>
              <input
                className="input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="row spaceSide">
              <span className="inputTitle">סיסמא:</span>
              <input
                className="input"
                onSubmit={handleSend}
                onKeyDown={handleKeyDown}
                type="password"
                value={password}
                onChange={changePassword}
              />
            </div>
          </div>
          <div className="row spaceSide">
            <span className="inputTitle question">טרם נרשמתם?</span>
            <button className="toLoginBtn">
              <Link to={ROUTES.SIGN_UP}>רישום</Link>
            </button>
          </div>
          <div className="row" style={{ justifyContent: "center" }}>
            <button
              className="toLoginBtn"
              onClick={forgot}
              style={{ width: 200 }}
            >
              שכחתי סיסמא
            </button>
          </div>
          {spinner()}
          <div className="centerBtn">
            <button className="loginBtn" onClick={handleSend}>
              היכנס
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
