import React, { useState, useEffect } from "react";
import { FaUserAlt } from "react-icons/fa";
import logoImg from "../photos/logo-large.png";
//@ts-ignore
import Select from "react-select";
import { activities } from "../data/activities";
import { auth, db } from "../services/firebase";
import { friquet } from "../data/friquent";
import { classProgramFunc } from "../data/classProgram";
import {
  sendCC,
  removeChild,
  addChildren,
  updateDetials,
  checkPolicy,
} from "../api";
import { ModalUpdate } from "../components/ModalUpdate";
import { Program, ProgramChildern, childrenObj } from "./screens.modal";

const Home = () => {
  const [user, setUser] = useState<string>();
  const [userUid, setUserUid] = useState<string>();
  const [userEmail, setUserEmail] = useState<string>();
  const [name, setName] = useState("");
  const [students, setStudents] = useState<Program[]>([]);
  const [program, setProgram] = useState<Program>();
  const [subProgram, setSubProgram] = useState("");
  const [children, setChildren] = useState<ProgramChildern[]>([]);
  const [ids, setIds] = useState<childrenObj[]>([]);
  const [classProgram, setClassProgram] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [modal, setModal] = useState(false);
  const [addChild, setAddChild] = useState("");
  const [addChildId, setAddChildId] = useState("");
  const [changeName, setChangeName] = useState("");
  const [changeLastName, setChangeLastName] = useState("");
  const [changePhone, setChangePhone] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [disablePolicy, setdisablePolicy] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [authorized, setAuthorized] = useState(false);

  // const tempActivites = [
  //   { value: "צהרון", label: "צהרון" },
  //   { value: "מצויינות בספורט", label: "מצויינות בספורט" },
  // ];

  useEffect(() => {
    (async () => {
      const res = await checkPolicy(auth().currentUser?.uid);
      if (res) {
        setChecked(true);
        setdisablePolicy(true);
      }
    })();
  }, []);

  useEffect(() => {
    const user = auth().currentUser;
    setUserEmail(user?.email as string);
    setUserUid(user?.uid);
    db.ref(`/users/${user?.uid}`)
      .once("value")
      .then((snapshot) => {
        setUser(snapshot.val().username);
        setPhoneNumber(snapshot.val().phone);
        // Authorization for testing
        // if (
        //   snapshot.val().email === "yaeli0205@gmail.com" ||
        //   snapshot.val().email === "adizonder@gmail.com" ||
        //   snapshot.val().email === "tal@tkd.co.il"
        // ) {
        //   setAuthorized(true);
        // }
      })
      .catch((err) => console.log(err));

    db.ref(`/users/${user?.uid}/childrens`)
      .once("value")
      .then((snapshot) => {
        const arr: ProgramChildern[] = [];
        const values: childrenObj[] = Object.values(snapshot.val());
        setIds(values);
        values.forEach((obj: childrenObj, index) => {
          arr.push({ value: index + 1, label: obj.name });
        });
        console.log("IDS", ids, arr);

        setChildren(arr);
      })
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const logout = () => {
    auth()
      .signOut()
      .then(() => {
        window.location.reload();
      })
      .catch(console.log);
  };
  const handleKeyDown = (e: KeyboardEvent) => {
    if (name === "") return;
    if (e.key === "Enter" || e.key === "Tab") {
      !students
        ? setStudents([createOption(name)])
        : setStudents([...students, createOption(name)]);
      setName("");
    } else {
      return null;
    }
  };
  const handleChange = (name: Program[]) => setStudents(name);

  const pickProgram = (program: Program) => {
    setClassProgram("");
    setSubProgram("");
    setProgram(program);
  };

  const setClassProgramFunc = (value: string) => {
    setClassProgram(value);
    setSubProgram("");
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  return (
    <div className="container mainBG">
      <div className="column">
        <div className="logout" dir="rtl">
          <h2>
            שלום <span>{user}</span>
          </h2>
          <FaUserAlt size={"30px"} />
          <div className="userButtons">
            <h3 className="logOutButton" onClick={() => setModal(true)}>
              ערוך פרטים
            </h3>
            <h3 className="logOutButton" onClick={logout}>
              התנתק
            </h3>
          </div>
        </div>
        <div className="mainCard">
          <div className="column">
            <img
              src={logoImg}
              className="imgCenter"
              width={"10%"}
              alt="עמותת הורים אלחריזי"
            />
            <h1 className="center big">רישום לפעילויות עמותת הורים אלחריזי</h1>
            {modal && <div className="modalBG"></div>}
            {modal && (
              <ModalUpdate
                setModal={setModal}
                changeName={changeName}
                setChangeName={setChangeName}
                changeLastName={changeLastName}
                setChangeLastName={setChangeLastName}
                changePhone={changePhone}
                setChangePhone={setChangePhone}
                schoolName={schoolName}
                setSchoolName={setSchoolName}
                addChildren={addChildren}
                addChild={addChild}
                setAddChildId={setAddChildId}
                addChildId={addChildId}
                setAddChild={setAddChild}
                setChildren={setChildren}
                children={children}
                removeChild={removeChild}
                updateDetials={updateDetials}
              />
            )}
          </div>
          <div className="row sections">
            <div
              className="column"
              style={{ flexGrow: 1, alignItems: "center" }}
            >
              <img
                src={logoImg}
                className="imgSide"
                width={"100%"}
                height={"auto"}
                alt="עמותת הורים אלחריזי"
              />
            </div>
            <div className="column" style={{ flexGrow: 2 }}>
              <div className="row rowCenter" dir="rtl">
                <h3
                  className="center"
                  style={{
                    color: "#667eea",
                    fontSize: 27,
                    marginBottom: 5,
                    fontWeight: 700,
                  }}
                >
                  שם הפעילות
                </h3>
              </div>
              <div className="rowCenter">
                <Select
                  isRtl={true}
                  placeholder={"בחרו פעילות"}
                  className="select"
                  // options={authorized ? activities : tempActivites}
                  options={activities}
                  value={program}
                  onChange={pickProgram}
                />
              </div>
              <div className="rowCenter">
                <Select
                  isRtl={true}
                  placeholder={"בחרו קבוצה"}
                  className="select"
                  options={classProgramFunc(program)}
                  value={classProgram}
                  onChange={setClassProgramFunc}
                />
              </div>
              <div className="rowCenter">
                <Select
                  isRtl={true}
                  placeholder={"בחרו תת קבוצה"}
                  className="select"
                  options={friquet(program, classProgram)}
                  onChange={setSubProgram}
                  value={subProgram}
                />
              </div>
              <div className="row rowCenter" dir="rtl">
                <h3
                  className="center"
                  style={{ fontSize: 25, marginBottom: 5 }}
                >
                  שמות הנרשמים
                </h3>
              </div>

              <div className="rowCenter names">
                <Select
                  isRtl
                  inputValue={name}
                  isClearable
                  isMulti
                  isDisabled={program?.value === "תשלומי עמותה" ? true : false}
                  options={children}
                  onChange={handleChange}
                  onInputChange={setName}
                  onKeyDown={handleKeyDown}
                  placeholder="שמות נרשמים"
                  value={students}
                />
                <div className="policy">
                  <a
                    href="https://drive.google.com/file/d/1t04BQKHgwfM2aC1sHEMw5neUJr2uGfCz/view?usp=sharing"
                    target="_blank"
                  >
                    תקנון העמותה
                  </a>
                  <label>
                    <input
                      type="checkbox"
                      disabled={disablePolicy}
                      checked={checked}
                      onChange={() => setChecked(!checked)}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row centerBtn">
            <button
              className="sendBtn"
              onClick={sendCC(
                program,
                students,
                subProgram,
                classProgram,
                userEmail,
                phoneNumber,
                userUid,
                checked,
                disablePolicy,
                ids
              )}
            >
              עבור לדף תשלום מאובטח באשראי
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
