import React from 'react';
import logoImg from '../photos/logo-large.png';
import '../css/style.css';

const Failer = () => {
    return (
        <div className="container loginFix">
            <div className="row" dir="rtl">
                <div className='loginFrame'>
                    <img src={logoImg} style={{ paddingTop: 10 }} className="imgCenter" width={'20%'} alt="עמותת הורים אלחריזי" />
                    <h1 style={{textAlign: 'center', color:'red'}}>ההרשמה נכשלה, אנא פנו למזכירות. עמכם הסליחה</h1>
                </div>
            </div>
        </div>

    )
}

export default Failer;
