import React from "react";
import { amutaPaymentConfig as inputOptions } from "../data/amutaPaymentConfig";
import { db, auth } from "../services/firebase";
import Swal from "@sweetalert/with-react";
import Swal2 from "sweetalert2";
import swal from "sweetalert";

export const sendCC =
  (
    program,
    students,
    subProgram,
    classProgram,
    userEmail,
    phoneNumber,
    userUid,
    checked,
    disablePolicy,
    ids
  ) =>
  async () => {
    if (!program?.label) {
      return Swal(
        <div>
          <h1 style={{ color: "red" }}>חסרים פרטים</h1>
          <p dir="rtl">אנא מלאו את כל הפרטים </p>
        </div>
      );
    }
    const sendConfirmed = (confirm, ap) => {
      if (confirm) {
        Swal(
          <div>
            <h1 style={{ color: "orange" }}>בתהליך...</h1>
          </div>
        );
        if (
          program === "" ||
          subProgram === "" ||
          (students.length === 0 && program.value !== "תשלומי עמותה")
        ) {
          return Swal(
            <div>
              <h1 style={{ color: "red" }}>חסרים פרטים</h1>
              <p>וודאו כי כל הפרטים מלאים</p>
            </div>
          );
        }
        if (program.length > 1) {
          const getProg = program.map((prog) => {
            return prog.value === "סטודיו מיתרים" ||
              prog.value === "מצויינות בספורט"
              ? false
              : true;
          });
          const res = getProg.every((program) => program === true);
          if (!res) {
            return Swal(
              <div>
                <h1 style={{ color: "red" }}>
                  לא ניתן לרשום את אחד מהחוגים יחדיו
                </h1>
                <p dir="rtl">
                  מצטערים, יש להירשם לחוגים מצויינות בספורט או סטודיו מיתרים
                  בנפרד
                </p>
              </div>
            );
          }
        }

        const childrenIds = [];
        const arrStudent = students.map((obj) => obj.label);
        for (let i = 0; i < arrStudent.length; i++) {
          for (let j = 0; j < ids.length; j++) {
            if (ids[j].name === arrStudent[i]) {
              if (!ids[j].id) {
                return Swal(
                  <div>
                    <h1 style={{ color: "red" }}>לא ניתן להירשם</h1>
                    <p dir="rtl">
                      נראה כי חסר תעודות זהות לאחד הנרשמים. יש לעדכן בערוך פרטים
                      בתפריט מעלה
                    </p>
                  </div>
                );
              }
              childrenIds.push(ids[j].id);
            }
          }
        }
        const sendData = {
          arrStudent:
            program.value === "תשלומי עמותה" ? ["תשלומי עמותה"] : arrStudent,
          subProgram,
          classProgram,
          taxId: childrenIds,
          userEmail,
          numberStudents:
            program.value === "תשלומי עמותה"
              ? ["תשלומי עמותה"]
              : students.length,
          program,
          phoneNumber,
          ap,
        };
        const promise = new Promise((resolve, reject) => {
          db.ref(`/vacancies/${program.label}/${classProgram.label}`).once(
            "value",
            (snapshot) => {
              const number = snapshot.val();
              number === 0 ? reject("program is full") : resolve();
            }
          );
        });
        promise
          .then(() => {
            fetch(`${process.env.PUBLIC_URL}/ccinfo`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(sendData),
            })
              .then((res) => res.json())
              .then((data) => {
                const urlRedirect = data.redirect;
                window.location = urlRedirect;
              })
              .catch((err) => {
                console.log(err);
                Swal(
                  <div>
                    <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
                    <p dir="rtl">
                      נא לפנות למזכירות להסדרת התשלום, עמכם הסליחה
                    </p>
                  </div>
                );
              });
          })
          .catch((err) => {
            console.log(err);
            Swal.close();
            Swal(
              <div>
                <h1 style={{ color: "red" }}>החוג מלא</h1>
                <p dir="rtl">
                  מצטערים, חוג זה הגיע למספר משתתפים מקסימלי על פי החלטת המפעיל,
                  יש לפנות אליו לבירורים.
                </p>
              </div>
            );
          });
      } else {
        return;
      }
    };
    if (!checked) {
      return Swal(
        <div>
          <h1 style={{ color: "red" }}>יש לאשר את תקנון העמותה</h1>
          <p dir="rtl">אנא קראו את התקנון ואשרו אותו בכפתור טרם בקשה לתשלום</p>
        </div>
      );
    } else if (!disablePolicy) {
      await signPolicy(userUid);
    }

    const res = checkRegister(userUid, program, students);
    res
      .then((ap) => {
        if (
          program.value === "טיסנאות" ||
          program.value === "כדורעף" ||
          program.value === "האלקטרונאי הצעיר" ||
          program.value === "בית הספר לקוסמים" ||
          program.value === "יסמין בהרפתקאה במוזיאון"
        ) {
          swal({
            title: "בפעילות זאת נדרש תשלום נוסף",
            text: "התשלום יבוצע ישירות למפעילים",
            icon: "warning",
            buttons: true,
          }).then((confirm) => {
            if (confirm) {
              sendConfirmed(true, ap);
            }
          });
        } else {
          sendConfirmed(true, ap);
        }
      })
      .catch((err) => {
        console.log(err);
        Swal(
          <div>
            <h1 style={{ color: "red" }}>הנכם רשומים כבר</h1>
            <p dir="rtl">
              לפי בדיקה במערכת הינכם רשומים כבר לפעילות הזאת, אם מצאתם לנכון
              שישנה טעות יש לפנות למזכירות בית הספר.
            </p>
          </div>
        );
      });
  };

const checkDuplicate = (resolve, reject, ref, students, ap, program) => {
  return ref.once("value", (snap) => {
    if (snap.val() !== null) {
      if (program === "תשלומי עמותה") {
        reject("already registered");
      }
      const registered = Object.values(snap.val());
      const kidsArr = Object.keys(registered);
      const studentsArr = students.map((student) => student.label);
      kidsArr.forEach((key, index) => {
        const kidExist = Object.keys(registered[index].registered);
        if (studentsArr.includes(kidExist[0])) {
          reject("already registered");
        }
      });
      resolve(ap);
    } else {
      resolve(ap);
    }
  });
};

const signPolicy = async (userUid) => {
  try {
    const date = new Date().toString();
    console.log(date);
    const ref = db.ref(`/users/${userUid}/policy`);
    await ref.set({
      policyApproved: date,
    });
  } catch (err) {
    console.log(err);
  }
};

export const checkPolicy = async (userUid) => {
  try {
    const ref = db.ref(`/users/${userUid}/policy`);
    const data = await ref.once("value");
    console.log(data.val());
    const value = data.val();
    if (!value) {
      return false;
    }
    return true;
  } catch (err) {
    console.log(err);
  }
};

export const checkRegister = (userUid, program, students) =>
  new Promise((resolve, reject) => {
    const ref = db.ref(`/users/${userUid}/programs/${program.label}`);
    //AMUTA PAYMENTS
    // const apRef = db.ref(`/users/${userUid}/programs/תשלומי עמותה`);
    // apRef.once("value", (snapshot) => {
    //   if (snapshot.val() === null && program.value !== "תשלומי עמותה") {
    //     Swal2.fire({
    //       title: "תשלומי עמותה",
    //       text: "אני מעוניין/ת להוסיף תשלום חד פעמי של דמי עמותה לטובת עשייה ופרוייקטים לרווחת התלמידים ובית הספר! אנא סמן/י:",
    //       input: "radio",
    //       icon: "question",
    //       inputOptions: inputOptions,
    //       showDenyButton: true,
    //       confirmButtonText: "אשר בחירה והמשך",
    //       denyButtonText: `לא מעוניין/ת כרגע, המשך`,
    //       inputValidator: (value) => {
    //         if (!value) {
    //           return "נא לבחור את אופציית התשלום הנוסף";
    //         }
    //       },
    //     }).then((res) => {
    //       return res.isConfirmed
    //         ? checkDuplicate(
    //             resolve,
    //             reject,
    //             ref,
    //             students,
    //             res.value,
    //             program.label
    //           )
    //         : checkDuplicate(
    //             resolve,
    //             reject,
    //             ref,
    //             students,
    //             null,
    //             program.label
    //           );
    //     });
    //   } else {
    return checkDuplicate(resolve, reject, ref, students, null, program.label);
    // }
    // });
  });

export const removeChild = (childrens, child, setChildren) => () => {
  const user = auth().currentUser;
  const ref = db.ref(`/users/${user.uid}/childrens`);
  ref.once("value").then((snap) => {
    const childrenDb = snap.val();
    const getKey = Object.entries(childrenDb);
    const filter = getKey.filter((objChildWithKey) => {
      return objChildWithKey[1].name === child;
    });

    const removeRef = db.ref(`/users/${user.uid}/childrens/${filter[0][0]}`);
    console.log(removeRef);
    removeRef.remove();
  });
  const arr = childrens.filter((obj, index) => {
    return obj.label !== child;
  });
  setChildren(arr);
};

export const addChildren = (
  addChild,
  addChildId,
  setAddChildId,
  setAddChild,
  children,
  setChildren
) => {
  if (addChild.length === 0) {
    return swal({
      title: "חסר שם",
      text: "מלאו את שם הילד",
      icon: "error",
    });
  }
  const childrenWithId = {
    name: addChild,
    id: addChildId,
  };
  const user = auth().currentUser;
  const ref = db.ref(`/users/${user.uid}/childrens`);
  ref.push().set(childrenWithId, (error) => {
    if (error) {
      swal({
        title: "תקלה בהוספת ילדים",
        text: "אנא פנו אל העמותה להוספה ידנית, עמכם הסליחה",
        icon: "error",
      });
    } else {
      setChildren([
        ...children,
        { value: children.length + 1, label: childrenWithId.name },
      ]);
      setAddChild("");
      setAddChildId("");
    }
  });
};

export const updateDetials =
  (phone, userSurename, username, setModal) => () => {
    const user = auth().currentUser;
    const ref = db.ref(`/users/${user.uid}/`);
    ref
      .once("value")
      .then((snapshot) => {
        return snapshot.val();
      })
      .then((oldData) => {
        ref.update(
          {
            phone: phone ? phone : oldData.phone,
            userSurename: userSurename ? userSurename : oldData.userSurename,
            username: username ? username : oldData.username,
          },
          (err) => {
            if (err) {
              swal({
                title: "תקלה בעדכון פרטים",
                text: "אנא פנו אל העמותה להסרה ידנית, עמכם הסליחה",
                icon: "error",
              });
            } else {
              swal({
                title: "!הפרטים עודכנו בהצלחה",
                icon: "success",
              });
              setModal(false);
            }
          }
        );
      })
      .catch((err) => console.log(err));
  };
