import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChild, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ChildrenCardProps } from "../screens/screens.modal";

export const ChildrenCard = (props: ChildrenCardProps) => {
  return (
    <div className="card-container" style={props?.customeStyle}>
      <div className="card-element">
        <FontAwesomeIcon size="2x" icon={faChild} />
        <span className="childText">{props.child}</span>
      </div>
      <div className="card-element buttonRemove">
        <button className="card-button">
          <FontAwesomeIcon
            size="2x"
            icon={faTimesCircle}
            onClick={props.removeChild}
          />
        </button>
      </div>
    </div>
  );
};
