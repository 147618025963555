import React from 'react';
import logoImg from '../photos/logo-large.png';
import { Link } from 'react-router-dom';
import * as ROUTES from '../consts/routes';
import '../css/style.css';

const Success = () => {
    return (
        <div className="container loginFix">
            <div className="row" dir="rtl">
                <div className='loginFrame'>
                    <img src={logoImg} style={{ paddingTop: 10 }} className="imgCenter" width={'20%'} alt="עמותת הורים אלחריזי" />
                    <h1 style={{ textAlign: 'center', color: '#4BB543' }}>נרשמתם בהצלחה!</h1>
                    <div className="row" style={{ justifyContent: 'center' }}>
                        <button className="toLoginBtn" style={{ width: 200 }}><Link to={ROUTES.HOME}>חזור לדף תשלום</Link></button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Success;
