import React, { } from 'react';
import logoImg from '../photos/logo-large.png';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const StartScreen = () => {
    return (
        <div className="container column">
                <img src={logoImg} alt="עמותת בית ספר אלחריזי" width={'20%'} height={'auto'} />
                <Loader
                    style={{marginTop: '20px'}}
                    type="Circles"
                    color="white"
                    height={100}
                    width={100}
                    
                />
        </div>
    )
}

export default StartScreen;

