import React, { useState } from "react";
import logoImg from "../photos/logo-large.png";
import { useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import Select from "react-select";
import { activities } from "../data/activities";

import Swal from "@sweetalert/with-react";
import "../css/style.css";

const Links = () => {
  const { price } = useParams();
  const [taxId, setTaxID] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [program, setProgram] = useState("");
  const [email, setEmail] = useState("");

  const sendData = () => {
    if (!name || !phone || !taxId) {
      Swal(
        <div>
          <h1 style={{ color: "red" }}>חסרים פרטים</h1>
          <p dir="rtl">מלאו את הפרטים הנדרשים</p>
        </div>
      );
    } else {
      setLoading(true);
      const sendData = {
        name,
        program: program.value,
        price,
        email,
        phoneNumber: phone,
        taxId,
      };
      fetch(`${process.env.PUBLIC_URL}/linkPay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendData),
      })
        .then((res) => res.json())
        .then((data) => {
          const urlRedirect = data.redirect;
          window.location = urlRedirect;
        })
        .catch((err) => {
          console.log(err);
          Swal(
            <div>
              <h1 style={{ color: "red" }}>אירעה שגיאה</h1>
              <p dir="rtl">נא לפנות למזכירות להסדרת התשלום, עמכם הסליחה</p>
            </div>
          );
        });
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="loginFrame">
          <img
            src={logoImg}
            style={{ paddingTop: 10 }}
            className="imgCenter"
            width={"20%"}
            alt="עמותת הורים אלחריזי"
          />
          <div className="modalHeader">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1
                style={{ textAlign: "center", color: "#4BB543", padding: 10 }}
              >
                לינק תשלום לחוג
              </h1>
              <h2
                style={{ textAlign: "center", color: "#4BB543", padding: 10 }}
              >
                {`המחיר לתשלום חד פעמי לחוג היינו: ${price}`}
              </h2>
            </div>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <span className="inputTitle">:שם מלא (הורה)</span>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <span className="inputTitle">:אימייל</span>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <span className="inputTitle">:מספר טלפון</span>
          </div>
          <div className="row modal-space">
            <input
              className="input heb modalInput"
              type="text"
              value={taxId}
              onChange={(e) => setTaxID(e.target.value)}
            />
            <span className="inputTitle">ת.ז לצורך הפקת קבלה</span>
          </div>
          <>
            <div className="row" style={{ justifyContent: "center" }} dir="rtl">
              <h3
                className="center"
                style={{
                  color: "#667eea",
                  fontSize: 27,
                  marginBottom: 5,
                  fontWeight: 700,
                }}
              >
                שם הפעילות
              </h3>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <Select
                isRtl={true}
                placeholder={"בחרו פעילות"}
                className="select"
                options={activities}
                value={program}
                onChange={setProgram}
              />
            </div>
          </>
          <div className="row modal-space">
            <button className="sendBtn" onClick={sendData}>
              עבור לדף תשלום מאובטח
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        {loading && (
          <Loader
            style={{
              marginTop: "20px",
              marginBottom: "20px",
              textAlign: "center",
            }}
            type="Circles"
            color="#fb00ff"
            height={100}
            width={100}
          />
        )}
      </div>
    </div>
  );
};

export default Links;
