import React, { useState, useEffect } from "react";
import SignUp from "./screens/SignUp";
import Login from "./screens/Login";
import Home from "./screens/Home";
import Success from "./screens/Success";
import Ap from "./screens/Ap";
import Tricking from "./screens/Tricking";
import Taekwondo from "./screens/Taekwondo";
import TaekwondoLate from "./screens/TaekwondoLate";
import TrickingLate from "./screens/TrickingLate";
import Mezianot from "./screens/Mezianot";
import Links from "./screens/Links";
import Failer from "./screens/Failer";
import StartScreen from "./screens/StartScreen";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import * as ROUTES from "./consts/routes";
import "./css/style.css";
import { auth } from "./services/firebase";
const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user && user.emailVerified) {
        setAuthenticated(true);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }, []);

  const GenericRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  };

  const PrivateRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated === true ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      />
    );
  };
  const PublicRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated === false ? (
            <Component {...props} />
          ) : (
            <Redirect to="/home" />
          )
        }
      />
    );
  };
  const MainRoute = ({ component: Component, authenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          authenticated === false && !loading ? (
            <Redirect
              to={{ pathname: "/signin", state: { from: props.location } }}
            />
          ) : authenticated === false && loading ? (
            <Component {...props} />
          ) : (
            <Redirect to="/home" />
          )
        }
      />
    );
  };
  return (
    <Router basename="/">
      <Switch>
        <MainRoute
          authenticated={authenticated}
          exact
          path={ROUTES.START}
          component={StartScreen}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.Links}
          component={Links}
        />
        <PrivateRoute
          authenticated={authenticated}
          path={ROUTES.HOME}
          component={Home}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.SUCCESS}
          component={Success}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.Ap}
          component={Ap}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.Mezianot}
          component={Mezianot}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.Taekwondo}
          component={Taekwondo}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.TaekwondoLate}
          component={TaekwondoLate}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.Tricking}
          component={Tricking}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.TrickingLate}
          component={TrickingLate}
        />
        <GenericRoute
          authenticated={authenticated}
          path={ROUTES.FAILER}
          component={Failer}
        />
        <PublicRoute
          authenticated={authenticated}
          path={ROUTES.SIGN_UP}
          component={SignUp}
        />
        <PublicRoute
          authenticated={authenticated}
          path={ROUTES.SIGN_IN}
          component={Login}
        />
      </Switch>
    </Router>
  );
};
export default App;
